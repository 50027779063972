import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import useSound from "use-sound";
import { useGetDashboardStatusAgent } from "../../hooks/useAgent";
import { useCODData } from "../../context/CODDataContext";

const AgentMainMenu = ({ ref, agentData, setOpen, open, logout }) => {
  const location = useLocation();
  const { data: menuData } = useGetDashboardStatusAgent();
  const { isAlertAgentWaiting } = useCODData();
  const restrictedPaths = ["/consultations", "/consultation"];
  const shouldPlaySound = !restrictedPaths.some((path) =>
    location.pathname.includes(path)
  );

  // Ref to store the previous state
  const previousStatusRef = useRef({});

  const [playEmergencyCare] = useSound(
    process.env.REACT_APP_ALERTU_NOTIFICATION,
    {
      volume: 0.9,
    }
  );
  const [playJingle] = useSound(process.env.REACT_APP_JINGLE_NOTIFICATION, {
    volume: 0.9,
  });

  useEffect(() => {
    const currentStatus = menuData?.agentDashboardMenuStatus || {};

    const playSounds = () => {
      if (!shouldPlaySound) return;

      // Play specific sound only if the count increased
      if (
        currentStatus.emergencyCareReq > 0 &&
        currentStatus.emergencyCareReq !==
          previousStatusRef.current.emergencyCareReq
      ) {
        playEmergencyCare();
      }

      // Update the previous status
      previousStatusRef.current = { ...currentStatus };
    };

    // Play sounds on state update
    playSounds();

    // Prevent the jingle logic if the path is restricted
    if (!shouldPlaySound) return;

    // Start the interval after the initial timeout
    const jingleInterval = setInterval(() => {
      if (currentStatus.emergencyCareReq > 0) {
        playJingle();
      }
    }, 37000);

    // Clear interval on unmount
    return () => clearInterval(jingleInterval);
  }, [
    menuData?.agentDashboardMenuStatus,
    location.pathname,
    shouldPlaySound,
    playEmergencyCare,
    playJingle,
  ]);
  const showAlert = isAlertAgentWaiting;

  return (
    <div className="navbar" ref={ref}>
      <div className="navbar__items nav__more__left">
        {agentData?.agentDetails?.agentType !== "AGENT_PT" && (
          <Link
            to="agent-alert"
            className={
              location.pathname.includes("/agent-alert") ? "sidebar_active" : ""
            }
          >
            {isAlertAgentWaiting && (
              <div className={showAlert ? "red__alert" : ""}></div>
            )}
            AlertU Services
          </Link>
        )}
        <Link
          to="/agent-dashboard"
          className={
            location.pathname.includes("/agent-dashboard")
              ? "sidebar_active"
              : ""
          }
        >
          {agentData?.agentDetails?.agentType !== "AGENT_PT"
            ? "Lab Services"
            : "Home Care"}
        </Link>
        {agentData?.agentDetails?.agentType !== "AGENT_PT" && (
          <Link
            to="agent-soboard"
            className={
              location.pathname.includes("/agent-soboard")
                ? "sidebar_active"
                : ""
            }
          >
            Second Opinion Services
          </Link>
        )}
        {agentData?.agentDetails?.agentType !== "AGENT_PT" && (
          <Link
            to="agent-diagnostic"
            className={
              location.pathname.includes("/agent-diagnostic")
                ? "sidebar_active"
                : ""
            }
          >
            Diagnostic Services
          </Link>
        )}
        {agentData?.agentDetails?.agentType !== "AGENT_PT" && (
          <Link
            to="agent-redcliff"
            className={
              location.pathname.includes("/agent-redcliff")
                ? "sidebar_active"
                : ""
            }
          >
            Red Cliff Services
          </Link>
        )}
      </div>
    </div>
  );
};

export default AgentMainMenu;
